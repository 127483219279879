import React from 'react';
import { Helmet } from "react-helmet";
import TemplateWrapper from '../components/layout';

const Okrashivanie = () => (
<TemplateWrapper>
  <div className="article-content">

    <Helmet>
      <title>Сложное окрашивание волос в Москве</title>
      <meta name="description" content="Прическа играет решающую роль когда нужно произвести впечатление. Какое окрашивание выбрать? Блонд или шоколад, а может быть шатуш или омбре, холодный русый или солнечный калифорнийский балаяж? Приходите мы поможем Вам приобразиться!" />
      <meta name="keywords" content="Мастер по окрашиванию волос, окрасить нарощенные волосы, окраска, окрашивание, красивые волосы, сложное окращивание в Москве, калифорнийский блонд, балаяж, омбре, мелирование, шатуш, русый, красивый цвет волос, модное окрашивание, смывка волос, окрашивание недорого, окрашивание дорого, тонирование волос лейбл, lebel, шатуш в Москве, колорист Москва, сложное окрашивание от специалиста, окрашивание на ленинском" />
    </Helmet>

    <h1 className="hm-title-1">Окрашивание волос в Москве</h1>
    <p>
      Окрашивание волос — проверенный и распространенный способ поддерживать достойный внешний вид и сохранять индивидуальность. В распоряжении женщин масса современных оттенков. Но для того чтобы получить желаемый цвет, не достаточно просто смешать нужные составляющие и нанести их на волосы — результат может заметно отличаться от представленного на пачке краски. Только профессионал может провести качественное окрашивание волос, сохранив из красоту и здоровый вид. Это очень важно: непрофессиональные краски способны нанести серьезный вред здоровью, вплоть до выраженной аллергической реакции и выпадения волос. Если вы хоть раз обращались к опытному мастеру, то наверняка знаете, насколько сильно отличается результат домашнего и салонного окрашивания. </p>
    <h2 className="hm-pink">Виды окрашивания волос</h2>
    <p>Самыми востребованными считаются следующие виды окрашивания волос:</p>

    <ul className="hm-list">
      <li>классическое окрашивание: мастер равномерно наносит краску на волосы, не используя никакие специальные приемы; </li>
      <li>мелирование: придание отдельным прядкам более светлых оттенков; </li>
      <li>колорирование: в отличие от мелирования, отдельные пряди не осветляются, а окрашивается в другой тон; </li>
      <li>шатуш: мастер создает естественный эффект выгорания волос, рельеф от затемненных корней к кончикам более светлого оттенка; </li>
      <li>окрашивание 3D: в основе метода лежит сочетание нескольких оттенков одного тона, что позволяет создавать удивительную палитру переливов и богатство цвета;</li>
      <li>омбре: волосы у корней темного цвета, а кончики имеют любой другой оттенок, вплоть до необычных и экстравагантных сочетаний;</li>
      <li>брондирование: техника многоступенчатого окрашивания волос;</li>
      <li>балаяж: изменение цвета только кончиков волос.</li>
    </ul>


    <p>Какой вид окрашивания выбрать? Решать только вам. Но рекомендуется прислушаться к мнению опытного стилиста, который умеет грамотно сочетать оттенки, парикмахерские техники и готов к смелым экспериментам. Мастер уже с первого взгляда на клиента понимает, какой вид окрашивания волос ему подойдет больше всего, а каких техник лучше всего избегать, чтобы не внести неудачные нотки в новый или старый образ.</p>

    <h2 className="hm-pink">Окрашивание волос в Москве: безопасно, эффектно, качественно</h2>
    <p>Предлагаю пройти окрашивание волос в Москве у профессионального стилиста. Над созданием уникального образа будет работать опытный мастер. Вы можете выбрать один или сразу несколько оттенков, безопасно осветлить волосы, чтобы кардинально изменить собственный стиль.</p>

    <p>Преимущества окрашивания волос у профессионала: </p>
    <ul className="hm-list">
      <li>мастер предоставляет клиенту практически неограниченные возможности для экспериментов и изменения образа;</li>
      <li>вы можете не беспокоиться за здоровье своих волос, так как хороший специалист пользуется средствами проверенных производителей с мировым именем;</li>
      <li>результат будет предсказуемым, чего нельзя сказать о домашнем окрашивании;</li>
      <li>мастер поможет выбрать подходящие техники, модные варианты сочетания оттенков и перехода одного цвета в другой;</li>
      <li>краска держится дольше, при этом волосы сохраняют свой блеск и природную красоту;</li>
      <li>профессионал не только окрашивает волосы, но и дает важные рекомендации по уходу, поддержанию достойного вида прически в домашних условиях.</li></ul>
    <p>
      Мы помогаем клиентам преобразиться, найти свой стиль через окрашивание и наращивание волос. Прическа играет решающую роль, когда необходимо произвести впечатление. Мало кто знает, что с помощью правильно подобранного оттенка и длины волос, парикмахерской техники можно не только придать человеку ухоженный вид, но и скрыть недостатки или усилить достоинства внешности.
</p>


  </div>
  </TemplateWrapper>
)

export default Okrashivanie